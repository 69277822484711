.registerButton {
  width: 160px;
}
.signUpButton {
  width: 200px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.account-selection-button-title {
  font-size: x-large;
  font-weight: 600;
}